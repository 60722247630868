@import '~bootstrap';
@import '~sweetalert2/src/sweetalert2.scss';


@import 'base/variables';
@import 'base/base';
@import 'base/forms';
@import 'base/animations';

@import 'layout/layout';
@import 'layout/header';
@import 'layout/footer';
