@keyframes breathing {
	0% {
	  opacity: 0.5;
	}

	50% {
		opacity: 1;
	}
  
	100% {
		opacity: 0.5;
	}
}

.breathing {
	animation: breathing 1.5s infinite normal;
}
