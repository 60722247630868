@import "~react-mde/lib/styles/css/react-mde-all.css";

.form-group--switch {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 0;

	.switch {
		order: -1;
		margin-right: $spacer / 2;
	}
}

.switch {
	position: relative;
	display: inline-block;
	width: 30px;
	height: 17px;

	input {
		width: 0;
		height: 0;
		opacity: 0;
	}

	.slider {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		cursor: pointer;
		background-color: $light;
		border-radius: 17px;
		transition: 0.4s;
	}

	.slider:before {
		position: absolute;
		bottom: 2px;
		left: 2px;
		width: 13px;
		height: 13px;
		background-color: $white;
		border-radius: 50%;
		content: "";
		transition: 0.4s;
	}

	input:checked + .slider {
		background-color: $primary;
	}

	input:focus + .slider {
		box-shadow: 0 0 1px $primary;
	}

	input:checked + .slider:before {
		-webkit-transform: translateX(13px);
		-ms-transform: translateX(13px);
		transform: translateX(13px);
	}
}

